import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import Swal from 'sweetalert2';
import { CadastroDocumentoAdmissionalService } from 'src/app/@core/@service/cadastro/documento-admissional/documento-admissional.service';
import { FornecedorHolerite } from '../../../../@core/@enum/enum';

declare var $;

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {

  gridData = [];

  matrizGridData = [];

  dadosModal: any = {};

  isEdit = false;

  //SELECTS
  empresaIdSelected = '';
  mostrarLinkSiteSelected = '';
  empresaEstadoSelected = '';
  empresaCidadeSelected = '';
  empresaStatusSelected = '';
  empresaTipoSelected = '';

  empresaMatrizSelected = '';

  empresaTipoList = [
    {
      Id: 1,
      Description: 'Matriz',
    },
    {
      Id: 2,
      Description: 'Filial',
    },
  ];

  mostrarLinkSiteList = [
    {
      Id: "Não",
      Description: "Não",
    },
    {
      Id: "Sim",
      Description: "Sim",
    },
  ];


  empresaStatusList = [
    {
      Id: 1,
      Description: 'Ativo',
    },
    {
      Id: 2,
      Description: 'Inativo',
    },
  ];

  fornecedoresHoleriteSelected = '';
  fornecedoresHolerite = [
    {
      Id: FornecedorHolerite.DATASUL,
      Description: 'Datasul'
    },
    {
      Id: FornecedorHolerite.DOMINIO,
      Description: 'Domínio'
    }
  ]

  cidadesList: Array<object> = [];
  estadosList: Array<object> = [];
  documentosList: Array<object> = [];
  documentosSelected: Array<any> = [];
  funcionalidadesList: Array<object> = [];
  funcionalidadesSelected: Array<any> = [];

  beneficiosList: Array<any> = [];
  cadastroList: Array<any> = [];
  controleDeFrequencia: any = {};
  ferias: any = {};
  folha: Array<any> = [];
  movimentacoes: Array<any> = [];

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('divMatriz', { static: true }) divMatriz: ElementRef;
  @ViewChild('inputMatriz', { static: true }) inputMatriz: ElementRef;
  @ViewChild('gridModalMatriz', { static: true }) gridModalMatriz: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroEmpresaService,
    private documentoAdmissionalService: CadastroDocumentoAdmissionalService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
    this.validarCampos();
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selectEstados') {
      this.empresaCidadeSelected = '';
      this.empresaEstadoSelected = event.target.value;
      this.cidadesList = await this.utilService.getCidades(event.target.value);
    } else if (event.srcElement.id == 'selectCidades') {
      this.empresaCidadeSelected = event.target.value;
    } else if (event.srcElement.id == 'selStatus') {
      $('#selStatus').addClass('element-invalid');
      this.empresaStatusSelected = event.target.value;
    } else if (event.srcElement.id == 'selTipo') {
      if (event.target.value == '1') {
        this.divMatriz.nativeElement.classList.add('matrizDisable');
        this.inputMatriz.nativeElement.classList.add('matrizDisableInput');
        $('#txtNomeEmpresa').prop('disabled', true);
        this.empresaMatrizSelected = 'data.EmpresaMatrizId';
        ($('#txtNomeEmpresa') as any).val('');
      } else {
        this.divMatriz.nativeElement.classList.remove('matrizDisable');
        this.inputMatriz.nativeElement.classList.remove('matrizDisableInput');
        $('#txtNomeEmpresa').prop('disabled', false);
        $('#txtNomeEmpresa').addClass('element-invalid');
      }
      $('#selTipo').removeClass('element-invalid');
      this.empresaTipoSelected = event.target.value;
    } else if (event.srcElement.id == 'selFornecedorHolerite') {
      this.fornecedoresHoleriteSelected = event.target.value;
      $('#selFornecedorHolerite').removeClass('element-invalid');
    }else if (event.srcElement.id == 'mostrarLinkSite') {
      this.mostrarLinkSiteSelected = event.target.value;
    }
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }


  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtNome: {
          required: true
        },
        txtCNPJ: {
          required: true, minlength: 18
        },
        txtPrimeiroContato: {
          minlength: 14
        },
        txtSegundoContato: {
          minlength: 14
        },
        txtTerceiroContato: {
          minlength: 14
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNome: {
          required: "Informe o Nome"
        },
        txtCNPJ: {
          required: "Informe o Cnpj",
          minlength: "Informe o Cnpj corretamente"
        },
        txtPrimeiroContato: {
          minlength: "Informe o 1º contato corretamente"
        },
        txtSegundoContato: {
          minlength: "Informe o 2º contato corretamente"
        },
        txtTerceiroContato: {
          minlength: "Informe o 3º contato corretamente"
        }
      },
    });
    $.validator.addMethod("cnpjCheck", function (value, element) {
      return UtilsService.verificarCNPJ(value);
    }, 'Cnpj inválido');
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
    this.getSelectData();
  }

  async getSelectData() {
    this.estadosList = await this.utilService.getEstados();
    this.cidadesList = [];
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Nome,
            cnpj: val.CNPJ,
            inscricaoEstadual: val.InscricaoEstadual,
            inscricaoMunicipal: val.InscricaoMunicipal,
            diasValidadeAso: val.DiasValidadeAso,
            diasVencimento1: val.DiasVencimento1,
            diasVencimento2: val.DiasVencimento2,
            diasVencimento3: val.DiasVencimento3,
            endereco: val.Endereco,
            enderecoNumero: val.Numero,
            bairro: val.Bairro,
            cep: val.CEP,
            uf: val.UF,
            cidade: val.Cidade,
            primeiroContato: val.TelefoneContato1,
            segundoContato: val.TelefoneContato2,
            terceiroContato: val.TelefoneContato3,
            primeiroContatoEmail: val.EmailContato1,
            segundoContatoEmail: val.EmailContato2,
            terceiroContatoEmail: val.EmailContato3,
            funcionalidades: val.EmpresaEmpresaFuncionalidades,
            dataCadastro: val.DataCadastro,
            status: val.EmpresaStatus,
            tipo: val.EmpresaTipo,
            matriz: val.EmpresaMatriz,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {

    // Desabilita modo edição
    this.isEdit = false;

    // Tirar element-invalids
    $('#selTipo').addClass('element-invalid');
    $('#txtNomeEmpresa').addClass('element-invalid');
    $('#selStatus').addClass('element-invalid');
    $('#selFornecedorHolerite').addClass('element-invalid');

    //SELECTS
    this.empresaEstadoSelected = '';
    this.empresaCidadeSelected = '';
    this.empresaStatusSelected = '';
    this.empresaTipoSelected = '';
    this.empresaMatrizSelected = '';
    this.fornecedoresHoleriteSelected = '';
    this.mostrarLinkSiteSelected = '';

    //Disabilita matriz
    this.divMatriz.nativeElement.classList.add('matrizDisable');
    this.inputMatriz.nativeElement.classList.add('matrizDisableInput');
    $('#txtNomeEmpresa').prop('disabled', true);

    //INPUTS
    ($('#txtNome') as any).val('');
    ($('#txtEndereco') as any).val('');
    ($('#txtCNPJ') as any).val('');
    ($('#txtNumeroEnd') as any).val('');
    ($('#txtInscEstadual') as any).val('');
    ($('#txtBairro') as any).val('');
    ($('#txtInscMunicipal') as any).val('');
    ($('#txtCEP') as any).val('');
    ($('#txtSegundoContato') as any).val('');
    ($('#txtEmailSegundo') as any).val('');
    ($('#txtPrimeiroContato') as any).val('');
    ($('#txtTerceiroContato') as any).val('');
    ($('#txtEmailPrimeiro') as any).val('');
    ($('#txtEmailTerceiro') as any).val('');
    ($('#txtNomeEmpresa') as any).val('');
    ($('#txtLinkSite') as any).val('');
  }

  getValuesFromInput() {
    return {
      Nome: $('#txtNome').val().toString().trim(),
      CNPJ: $('#txtCNPJ').val().toString().trim(),
      InscricaoEstadual: $('#txtInscEstadual').val().toString().trim(),
      InscricaoMunicipal: $('#txtInscMunicipal').val().toString().trim(),
      DiasValidadeAso: $('#txtDiasValidadeAso').val().toString().trim(),
      DiasVencimento1: $('#txtDiasVencimento1').val().toString().trim(),
      DiasVencimento2: $('#txtDiasVencimento2').val().toString().trim(),
      DiasVencimento3: $('#txtDiasVencimento3').val().toString().trim(),
      Endereco: $('#txtEndereco').val().toString().trim(),
      Numero: $('#txtNumeroEnd').val().toString().trim(),
      Bairro: $('#txtBairro').val().toString().trim(),
      CEP: $('#txtCEP').val().toString().trim(),
      UF: this.empresaEstadoSelected,
      Cidade: this.empresaCidadeSelected,
      TelefoneContato1: $('#txtPrimeiroContato').val().toString().trim(),
      TelefoneContato2: $('#txtSegundoContato').val().toString().trim(),
      TelefoneContato3: $('#txtTerceiroContato').val().toString().trim(),
      EmailContato1: $('#txtEmailPrimeiro').val().toString().trim(),
      EmailContato2: $('#txtEmailSegundo').val().toString().trim(),
      EmailContato3: $('#txtEmailTerceiro').val().toString().trim(),
      EmpresaStatusId: this.empresaStatusSelected,
      EmpresaTipoId: this.empresaTipoSelected,
      EmpresaMatrizId: this.empresaMatrizSelected,
      FornecedorHolerite: this.fornecedoresHoleriteSelected,
      LinkSite: $('#txtLinkSite').val().toString().trim(),
      MostrarLinkSite: this.mostrarLinkSiteSelected
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    const isValid = ($("#formulario") as any).valid();
    if (!this.empresaStatusSelected || !this.empresaTipoSelected || !this.fornecedoresHoleriteSelected || (this.empresaTipoSelected == '2' && !this.empresaMatrizSelected)) {
      if (!this.empresaTipoSelected)
        $('#selTipo').addClass('element-invalid');
      if (!this.empresaStatusSelected)
        $('#selStatus').addClass('element-invalid');
      if (this.empresaTipoSelected == '2' && !this.empresaMatrizSelected)
        $('#txtNomeEmpresa').addClass('element-invalid');
      if (!this.fornecedoresHoleriteSelected)
        $('#selFornecedorHolerite').addClass('element-invalid');
    } else if (isValid) {
      $('#txtNomeEmpresa').removeClass('element-invalid');
      const obj: any = this.getValuesFromInput();
      if (this.isEdit) {
        obj.Id = this.empresaIdSelected;
        this.moduleService.editar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('edit-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('edit-error');
          }
        );
      } else {
        this.moduleService.adicionar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalMatriz') {
      if ($('#txtNomeEmpresa').val().toString().length > 0) {
        this.moduleService.buscarEmpresaMatrizPorNome($('#txtNomeEmpresa').val().toString()).subscribe(
          res => {
            this.matrizGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cnpj: val.CNPJ,
                ...val
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalMatriz(this.matrizGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            console.log(err);
          }
        );
      }
    } else if (idModal == 'modalFuncionalidade') {
      this.moduleService.buscarFuncionalidades().subscribe(
        res => {
          this.beneficiosList = res.filter(x => x.Grupo == 'Benefícios').map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              isSelected: this.funcionalidadesSelected.filter(x => x.EmpresaFuncionalidadeId == val.Id),
              ...val
            }));
          this.cadastroList = res.filter(x => x.Grupo == 'Cadastro').map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              isSelected: this.funcionalidadesSelected.filter(x => x.EmpresaFuncionalidadeId == val.Id),
              ...val
            }));
          this.controleDeFrequencia = {
            gestorList: res.filter(x => x.Grupo == 'Controle de Frequência' && x.TipoFuncionario == 'Gestor').map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                isSelected: this.funcionalidadesSelected.filter(x => x.EmpresaFuncionalidadeId == val.Id),
                ...val
              })),
            funcionarioList: res.filter(x => x.Grupo == 'Controle de Frequência' && x.TipoFuncionario == 'Funcionário').map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                isSelected: this.funcionalidadesSelected.filter(x => x.EmpresaFuncionalidadeId == val.Id),
                ...val
              })),
          };
          this.ferias = {
            gestorList: res.filter(x => x.Grupo == 'Férias' && x.TipoFuncionario == 'Gestor').map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                isSelected: this.funcionalidadesSelected.filter(x => x.EmpresaFuncionalidadeId == val.Id),
                ...val
              })),
            funcionarioList: res.filter(x => x.Grupo == 'Férias' && x.TipoFuncionario == 'Funcionário').map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                isSelected: this.funcionalidadesSelected.filter(x => x.EmpresaFuncionalidadeId == val.Id),
                ...val
              })),
          };
          this.folha = res.filter(x => x.Grupo == 'Folha de Pagamento').map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              isSelected: this.funcionalidadesSelected.filter(x => x.EmpresaFuncionalidadeId == val.Id),
              ...val
            }));
          this.movimentacoes = res.filter(x => x.Grupo == 'Movimentações Cadastrais').map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              isSelected: this.funcionalidadesSelected.filter(x => x.EmpresaFuncionalidadeId == val.Id),
              ...val
            }));
            console.log(res);
          ($(`#${idModal}`) as any).modal('show');
        },
        err => {
          console.log(err);
        }
      );
      this.dadosModal = data;
      ($(`#${idModal}`) as any).modal('show');
    } else if (idModal == 'modalDocumentos') {
      this.documentoAdmissionalService.pesquisar().subscribe(
        res => {
          console.log(this.documentosSelected);
          this.documentosList = res.map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              obrigatorio: this.documentosSelected.filter(x => x.DocumentoAdmissionalId == val.Id && x.Obrigatorio == true),
              isSelected: this.documentosSelected.filter(x => x.DocumentoAdmissionalId == val.Id),
              ...val
            })
          ).sort(this.utilService.dynamicSort('nome'));
          ($(`#${idModal}`) as any).modal('show');
        },
        err => {
          console.log(err);
        }
      );
      this.dadosModal = data;
      ($(`#${idModal}`) as any).modal('show');
    }
  }

  selectDocumentoRadio(idEl = '', idDoc = '', obrigatorio = '') {
    if(this.documentosSelected.some(e => e.DocumentoAdmissionalId == idDoc)){
      this.documentosSelected.splice(this.documentosSelected.findIndex(e => e.DocumentoAdmissionalId == idDoc), 1);
    }
    if ($(`#${idEl}`).is(":checked")) {
      this.documentosSelected.push(
        {
          EmpresaId: this.empresaIdSelected,
          DocumentoAdmissionalId: idDoc,
          Obrigatorio: ($(`#${obrigatorio}`).is(":checked")) ,
        }
      )
    } else {
      this.documentosSelected.splice(this.documentosSelected.findIndex(e => e.DocumentoAdmissionalId == idDoc), 1);
    }
  }

  salvarDocumentosModal() {
    this.documentosSelected.forEach(element => {
      delete element.DocumentoAdmissional;
    });
    this.moduleService.adicionarEmpresaDocumento(this.documentosSelected).subscribe(
      res => {
        if (res.Codigo && res.Codigo == '600') {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: res.Descricao || 'Ocorreu um erro',
            showConfirmButton: false,
            timer: 2000
          });
        } else {
          Swal.fire({
            position: 'top',
            icon: 'success',
            title: 'Documentos adicionados com sucesso!',
            showConfirmButton: false,
            timer: 2000
          });
          this.getDataGrid();
          this.closeModal('modalDocumentos');
        }
      },
      err => {
        console.log(err);
        this.utilService.alertSwal('add-error');
      }
    );
  }

  selectFuncionalidadeRadio(idEl = '', idFuncionalidade = '') {
    if ($(`#${idEl}`).is(":checked")) {
      this.funcionalidadesSelected.push(
        {
          EmpresaId: this.empresaIdSelected,
          EmpresaFuncionalidadeId: idFuncionalidade,
        }
      )
    } else {
      this.funcionalidadesSelected.splice(this.funcionalidadesSelected.findIndex(e => e.EmpresaFuncionalidadeId == idFuncionalidade), 1);
    }
  }

  salvarFuncionalidadeModal() {
    this.funcionalidadesSelected.forEach(element => {
      delete element.EmpresaFuncionalidade;
    });
    this.moduleService.editarEmpresaFuncionalidade(this.funcionalidadesSelected).subscribe(
      res => {
        if (res.Codigo && res.Codigo == '600') {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: res.Descricao || 'Ocorreu um erro',
            showConfirmButton: false,
            timer: 2000
          });
        } else {
          Swal.fire({
            position: 'top',
            icon: 'success',
            title: 'Funcionalidades adicionadas com sucesso!',
            showConfirmButton: false,
            timer: 2000
          });
          this.getDataGrid();
          this.closeModal('modalFuncionalidade');
        }
      },
      err => {
        console.log(err);
        this.utilService.alertSwal('add-error');
      }
    );
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }


  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
        {
          "data": "inscricaoEstadual"
        },
        {
          "data": "inscricaoMunicipal"
        },
        {
          "data": 'endereco', "orderable": false, "render": function (data, type, row) {
            return `${row.endereco} - ${row.enderecoNumero}`;
          }
        },
        {
          "data": "bairro"
        },
        {
          "data": "cep"
        },
        {
          "data": 'uf', "orderable": false, "render": function (data, type, row) {
            return row.uf ? `${self.estadosList.filter(val => val['Id'] == row.uf)[0]['Description']} - ${row.uf}` : '-';
          }
        },
        {
          "data": 'cidade', "orderable": false, "render": function (data, type, row) {
            return row.cidade ? row.cidade : '-';
          }
        },
        {
          "data": "primeiroContato"
        },
        {
          "data": "primeiroContatoEmail"
        },
        {
          "data": "segundoContato"
        },
        {
          "data": "segundoContatoEmail"
        },
        {
          "data": "terceiroContato"
        },
        {
          "data": "terceiroContatoEmail"
        },
        {
          "data": 'status', "orderable": false, "render": function (data, type, row) {
            return data ? data.Nome : '-';
          }
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": "LinkSite"
        },
        {
          "data": "MostrarLinkSite"
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle;"><button type="button" class="fa fa-file-image-o" style="color: rgb(16, 15, 92); border: 0px; background-color: transparent;" title="Documentos"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle;"><button type="button" class="fa fa-gears" style="color: rgb(5, 90, 5); border: 0px; background-color: transparent;" title="Funcionalidades"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        }
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[19]).on("click", function (e) {
          self.empresaIdSelected = data.id;
          self.documentosSelected = data.EmpresaDocumentosAdmissional;
          self.openModal('modalDocumentos', data);
        });
        $($(row).children()[20]).on("click", function (e) {
          self.empresaIdSelected = data.id;
          self.funcionalidadesSelected = data.EmpresaEmpresaFuncionalidades;
          self.openModal('modalFuncionalidade', data);
        });
        $($(row).children()[21]).on("click", async function (e) {
          // Seta modo edição
          self.isEdit = true;

          // Preencher campos, com a linha selecionada
          //SELECTS
          self.empresaEstadoSelected = data.uf;
          self.cidadesList = await self.utilService.getCidades(data.uf);
          self.empresaCidadeSelected = data.cidade;
          self.empresaStatusSelected = data.status.Id;
          self.empresaTipoSelected = data.tipo.Id;
          self.fornecedoresHoleriteSelected = data.FornecedorHolerite;
          self.mostrarLinkSiteSelected = data.MostrarLinkSite;

          //INPUTS
          ($('#txtNome') as any).val(data.nome);
          ($('#txtEndereco') as any).val(data.endereco);
          ($('#txtCNPJ') as any).val(data.cnpj);
          ($('#txtNumeroEnd') as any).val(data.enderecoNumero);
          ($('#txtInscEstadual') as any).val(data.inscricaoEstadual);
          ($('#txtBairro') as any).val(data.bairro);
          ($('#txtInscMunicipal') as any).val(data.inscricaoMunicipal);
          ($('#txtDiasValidadeAso') as any).val(data.diasValidadeAso);
          ($('#txtDiasVencimento1') as any).val(data.diasVencimento1);
          ($('#txtDiasVencimento2') as any).val(data.diasVencimento2);
          ($('#txtDiasVencimento3') as any).val(data.diasVencimento3);
          ($('#txtCEP') as any).val(data.cep);
          ($('#txtEmailPrimeiro') as any).val(data.primeiroContatoEmail);
          ($('#txtEmailSegundo') as any).val(data.segundoContatoEmail);
          ($('#txtEmailTerceiro') as any).val(data.terceiroContatoEmail);
          ($('#txtPrimeiroContato') as any).val(data.primeiroContato);
          ($('#txtSegundoContato') as any).val(data.segundoContato);
          ($('#txtTerceiroContato') as any).val(data.terceiroContato);
          ($('#txtLinkSite') as any).val(data.LinkSite);

          if (data.tipo.Nome == 'Filial' && data.EmpresaMatriz) {
            self.divMatriz.nativeElement.classList.remove('matrizDisable');
            self.inputMatriz.nativeElement.classList.remove('matrizDisableInput');
            self.empresaMatrizSelected = data.EmpresaMatrizId;
            ($('#txtNomeEmpresa') as any).val(data.EmpresaMatriz.Nome);
          }


          self.empresaIdSelected = data.id;
        });
        $($(row).children()[22]).on("click", function (e) {
          self.remover(data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalMatriz(data) {
    let self = this;
    this.dataTable = $(this.gridModalMatriz.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {

          self.empresaMatrizSelected = data.id;
          $('#txtNomeEmpresa').val(data.nome);
          $('#txtNomeEmpresa').removeClass('element-invalid');
          self.closeModal('modalMatriz');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });


  }
}
